import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Blog from "../components/blog"
import Contact from "../components/contact"

function encode(data) {
	return Object.keys(data)
			.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
			.join("&")
}

const handleSubmit = (event) => {
	event.preventDefault();

	event.target.classList.add('is-loading');

  var name = document.getElementById('_name').value;
  var email = document.getElementById('_email').value;
  var message = document.getElementById('_message').value;

  if (!name || !email || !message) {
    alert('Gelieve alle velden in te vullen.')
    return;
  }

	document.getElementById('_submit').disabled = 'disabled';
	document.getElementById('_submit').innerText = 'Verzenden..';

	fetch("/", {
		method: "POST",
		headers: { "Content-Type": "application/x-www-form-urlencoded" },
		body: encode({
			"form-name": event.target.getAttribute("name"),
			name: name,
			email: email,
			message: message,
			page: window.localStorage.getItem('LAST_PROPERTY') || window.location.href,
		})
	})
	.then(() => {
		event.target.classList.add('is-completed');
    document.getElementById('_submit').innerText = 'Bericht verzonden!';
	})
	.catch(error => {
    document.getElementById('_submit').disabled = '';
		alert(error);
	})
	.finally(() => {
		event.target.classList.remove('is-loading');
	});
}

const seo = {
  metaTitle: 'Contacteer ons',
}

const ContactPage = () => (
  <Layout active="contact" page="contact">
    <Seo seo={seo} />
    <div className="container" style={{ paddingTop: 0, paddingBottom: 60 }}>
      <div className="row">
        <div className="custom-col-12" style={{ maxWidth: 800 }}>
          <header className="entry-header heading">
            <h2 className="entry-title">Contacteer ons</h2>
            <p>Heeft u een interesse in een pand, een vraag of een opmerking? Gebruik dan onderstaand formulier om ons te contacteren en wij nemen zo snel mogelijk contact op.</p>
          </header> 
        </div>
        <div className="custom-col-12" style={{ marginTop: -20, marginBottom: 20 }}>
          <Contact />
        </div>
        <div className="custom-col-12" style={{ maxWidth: 600 }}>
          <form name="contact_full" method="POST" className="row" data-netlify="true" onSubmit={handleSubmit}>
            <div className="custom-col-6">
              <label htmlFor="_name">Uw naam</label>
              <input type="text" id="_name" name="name" />
            </div>
            <div className="custom-col-6">
              <label htmlFor="_email">Uw email</label>
              <input type="email" id="_email" name="email" />
            </div>
            <div className="custom-col-12">
              <label htmlFor="_message">Uw bericht</label>
              <textarea id="_message" name="message" rows="8"></textarea>
            </div>
            <button className="box-button" type="submit" id="_submit">Verzenden</button>
            <input type="hidden" name="form-name" value="contact_full" />
            <div style={{display: 'none'}}>
              <label htmlFor="page">Pagina</label>
              <input type="text" id="page" name="page" value="contact" />
            </div>
          </form>
        </div>
      </div>
    </div>
    <Blog items={3} />
  </Layout>
)

export default ContactPage
